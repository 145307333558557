import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionWork from "../components/Section/SectionWork"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const WorkPage = () => (
  <Layout>
    <Seo title="Trabalhe conosco" />
    <SectionWork />
    <WppButtonFloat />
  </Layout>
)

export default WorkPage

import styled from "styled-components"

import { Form as FormComponent } from "../"

export const Form = styled(FormComponent)`
  margin-top: 15px;

  .form-group {
    .file-input {
      width: 100%;
      .content {
        position: relative;
        display: flex;
        // flex-direction: column;
        align-items: center;
        // justify-content: center;
        border: 1px solid var(--color-grayscale3);
        background-color: var(--color-white);
        height: 45px;
        padding: 3px 10px 0;
        border-radius: 2px;
        transition: all ease 0.3s;
        &:hover {
          border-color: var(--color-grayscale5);
        }

        .btn-target {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          opacity: 0;
        }

        .placeholder-label {
          display: flex;
          align-items: flex-end;
          color: var(--color-black);
          font-size: 1rem;

          .icon {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }
        }
      }

      // variants
      &.-is-invalid {
        .content {
          border-color: var(--color-danger);
        }
      }
    }
  }
`

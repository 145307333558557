import * as React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { IMaskInput } from "react-imask"
import axios from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import * as S from "./styled"
import { Button } from "../../Button"
import Select from "../../Select"

import { FileEarmarkArrowUpFill as FileIcon } from "@styled-icons/bootstrap/FileEarmarkArrowUpFill"

const SweetAlert = withReactContent(Swal)

const FormWork = () => {
  const fileInputCurriculum = React.createRef()

  const formWorkInitialValues = {
    applicationName: "",
    applicationEmail: "",
    applicationPhone: "",
    applicationAddress: "",
    applicationExperience: {
      selectedOption: { value: "", label: "Selecione" },
      options: [
        { value: "Sim", label: "Sim" },
        { value: "Não", label: "Não" },
      ],
    },
    applicationOccupation: {
      selectedOption: { value: "", label: "Selecione" },
      options: [
        { value: "Advogado", label: "Advogado" },
        { value: "Parceiro de negócios", label: "Parceiro de negócios" },
        { value: "Secretário", label: "Secretário" },
        { value: "Estágio", label: "Estágio" },
        { value: "Outro (descreva abaixo)", label: "Outro (descreva abaixo)" },
      ],
    },
    applicationDescription: "",
    applicationCurriculumUploadFile: "",
  }

  const formWorkValidationSchema = Yup.object().shape({
    applicationName: Yup.string().required("Campo obrigatório"),
    applicationEmail: Yup.string()
      .required("Campo obrigatório")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Digite um email válido"
      ),
    applicationPhone: Yup.string()
      .min(13, "Digite um telefone válido (com DDD)")
      .max(14, "Digite um telefone válido (com DDD)")
      .required("Campo obrigatório"),
    applicationAddress: Yup.string().required("Campo obrigatório"),
    applicationExperience: Yup.object().shape({
      selectedOption: Yup.object().shape({
        value: Yup.string().required("Selecione uma opção"),
      }),
    }),
    applicationOccupation: Yup.object().shape({
      selectedOption: Yup.object().shape({
        value: Yup.string().required("Selecione uma opção"),
      }),
    }),
    applicationDescription: Yup.string().required("Campo obrigatório"),
    applicationCurriculumUploadFile: Yup.mixed()
      .required("Anexe seu currículo")
      .test(
        "is-file-correct-type",
        "Selecione um arquivo DOC, DOCX, TXT, PDF, JPEG, JPG ou PNG.",
        value =>
          !value ||
          [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
          ].includes(value.type)
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
  })

  const uploadCurriculum = async file => {
    if (file) {
      console.log(file)
      const formDataUploadCurriculum = new FormData()
      formDataUploadCurriculum.append("files", file, file.name)

      // eslint-disable-next-line no-unused-vars
      const responseDataFileUpload = await axios({
        method: "post",
        url: "https://ruy-molina-back.herokuapp.com/upload",
        data: formDataUploadCurriculum,
      })

      return responseDataFileUpload
    }
  }

  const formWorkOnSubmit = async (values, { resetForm }) => {
    const {
      applicationName,
      applicationEmail,
      applicationPhone,
      applicationAddress,
      applicationExperience,
      applicationOccupation,
      applicationDescription,
      applicationCurriculumUploadFile,
    } = values

    const currentUrl = typeof window !== "undefined" ? window.location.href : ""

    // Enviar para
    //const emailTo = "pontesraniery@gmail.com"
    const emailTo = "contato@ruymolina.adv.br"
    // Assunto
    const emailSubject = `[TRABALHE CONOSCO] - Ruy Molina`

    const curriculum = await uploadCurriculum(applicationCurriculumUploadFile)

    const applicationCurriculumLink = curriculum.data[0].url
      ? curriculum.data[0].url
      : "Não enviado"

    const formData = new FormData()
    formData.append("to", emailTo)
    formData.append("subject", emailSubject)
    formData.append(
      "html",
      `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="https://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
          <!--[if gte mso 9]>
            <xml>
              <o:OfficeDocumentSettings>
              <o:AllowPNG/>
              <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
          <title>${emailSubject}</title>
          <meta http–equiv="Content-Type" content="text/html; charset=utf-8">
          <meta http–equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0 ">
          <meta name="format-detection" content="telephone=no">
          <!--[if !mso]><!-->
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800" rel="stylesheet">
          <!--<![endif]-->
          <style type="text/css">
            body {
              margin: 0 !important;
              padding: 0 !important;
              -webkit-text-size-adjust: 100% !important;
              -ms-text-size-adjust: 100% !important;
              -webkit-font-smoothing: antialiased !important;
            }
            img {
              border: 0 !important;
              outline: none !important;
            }
            p {
              Margin: 0px !important;
              Padding: 0px !important;
            }
            table {
              border-collapse: collapse;
              mso-table-lspace: 0px;
              mso-table-rspace: 0px;
            }
            td, a, span {
              border-collapse: collapse;
              mso-line-height-rule: exactly;
            }
            .ExternalClass * {
              line-height: 100%;
            }
            .email_defaultlink a {
              color: inherit !important;
              text-decoration: none !important;
            }
            span.MsoHyperlink {
              mso-style-priority: 99;
              color: inherit;
            }
            span.MsoHyperlinkFollowed {
              mso-style-priority: 99;
              color: inherit;
            }
            
            @media only screen and (min-width:481px) and (max-width:699px) {
              .email_main_table {
                width: 100% !important;
              }
              .email_wrapper {
                width: 100% !important;
              }
              .email_hide {
                display: none !important;
              }
              .email_img {
                width: 100% !important;
                height: auto !important;
              }
              .email_h20 {
                height: 20px !important;
              }
              .email_padd {
                padding: 20px 10px !important;
              }
            }
            
            @media screen and (max-width: 480px) {
              .email_main_table {
                width: 100% !important;
              }
              .email_wrapper {
                width: 100% !important;
              }
              .email_hide {
                display: none !important;
              }
              .email_img {
                width: 100% !important;
                height: auto !important;
              }
              .email_h20 {
                height: 20px !important;
              }
              .email_padd {
                padding: 20px 10px !important;
              }
              .email_text1 {
                font-size: 16px !important;
                line-height: 24px !important;
              }
              u + .email_body .email_full_wrap {
                width: 100% !important;
                width: 100vw !important;
              }
            }
          </style>
        </head>
        <body class="email_body" style="margin:0px; padding:0px;" bgcolor="#efefef">
          <table class="email_full_wrap" valign="top" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#efefef" align="center">
            <tbody>
              <tr>
                <td valign="top" align="center">
                  <table class="email_main_table" style="width:700px;" width="700" cellspacing="0" cellpadding="0" border="0" align="center">
                    <tbody>
                    <!-- Header Section -->
                    <tr>
                      <td style="padding:35px 70px 30px;" class="email_padd" valign="top" bgcolor="#393E46" align="center">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:25px; font-weight:bold; line-height:22px; color:#ffffff; text-transform:normal; letter-spacing:1px; padding-bottom:0px;" valign="top" align="center">Ruy Molina Advocacia</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- //Header Section -->
                    
                    <!-- Content Section -->
                    <tr>
                      <td style="padding:35px 70px 30px;" class="email_padd" valign="top" bgcolor="#ffffff" align="center">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:20px; text-transform:uppercase; line-height:26px; letter-spacing:1px; color:#1B1B1B;" valign="top" align="center">Candidatura</td>
                              </tr>
                            <tr>
                              <td style="font-size:0px; line-height:0px; height:45px;" height="45">&nbsp;</td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Nome: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationName}</span></td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Email: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationEmail}</span></td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Telefone: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationPhone}</span></td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Endereço: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationAddress}</span></td>
                            </tr>
                            ${
                              applicationExperience &&
                              applicationExperience.selectedOption.value
                                ? `
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Experiência relacionada ao engajamento social: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationExperience.selectedOption.value}</span></td>
                            </tr>
                            `
                                : ""
                            }
                            ${
                              applicationOccupation &&
                              applicationOccupation.selectedOption.value
                                ? `
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Ocupação: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationOccupation.selectedOption.value}</span></td>
                            </tr>
                            `
                                : ""
                            }
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:5px;" valign="top" align="left">Descrição do candidato:</td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; line-height:25px; color:#4e4e4e; padding-bottom:15px; font-size:18px; font-weight:normal; text-transform:normal; letter-spacing: 0px;" valign="top" align="left">${applicationDescription}</td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Link do currículo: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${applicationCurriculumLink}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- //Content Section -->
      
                    <!-- Small Footer Section -->
                    <tr>
                      <td style="padding: 15px;" class="email_padd" valign="top" bgcolor="#393E46" align="center">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:12px; line-height:15px; color:#ffffff;" valign="top" align="center">Ruy Molina Web | Formulário de <a href="${currentUrl}" target="_blank" style="color:#ffffff; text-decoration:underline;">Trabalhe Conosco</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- //Small Footer Section -->
                    
                    <tr>
                      <td class="email_hide" style="line-height:1px;min-width:700px;background-color:#ffffff;"><img alt="" src="images/spacer.gif" style="max-height:1px; min-height:1px; display:block; width:700px; min-width:700px;" width="700" border="0" height="1"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
              </tr>
            </tbody>
          </table>
      
          <div class="email_hide" style="white-space: nowrap; display: none; font-size:0px; line-height:0px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        </body>
      </html>
      `
    )

    try {
      // eslint-disable-next-line no-unused-vars
      const responseData = await axios({
        method: "post",
        url: "https://ruy-molina-back.herokuapp.com/email",
        data: formData,
      })
      // console.log(responseData)
      SweetAlert.fire({
        title: "Sucesso!",
        text: "Sua candidatura foi enviada com sucesso!",
        icon: "success",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 3500,
      })
      resetForm()
    } catch (error) {
      SweetAlert.fire({
        title: "Oops...",
        text: "Algo deu errado, tente novamente mais tarde.",
        icon: "error",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      })
    }
  }

  return (
    <Formik
      validationSchema={formWorkValidationSchema}
      onSubmit={formWorkOnSubmit}
      initialValues={formWorkInitialValues}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <S.Form autoComplete="off" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="label" htmlFor="id_applicationName">
              Nome *
            </label>
            <input
              className={`form-control ${
                touched.applicationName && errors.applicationName
                  ? "-is-invalid"
                  : ""
              }`}
              id="id_applicationName"
              name="applicationName"
              placeholder="Informe seu nome"
              type="text"
              value={values.applicationName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {touched.applicationName && errors.applicationName && (
              <span className="error-message">{errors.applicationName}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_applicationEmail">
              Email *
            </label>
            <input
              className={`form-control ${
                touched.applicationEmail && errors.applicationEmail
                  ? "-is-invalid"
                  : ""
              }`}
              id="id_applicationEmail"
              name="applicationEmail"
              placeholder="exemplo@email.comm"
              type="email"
              value={values.applicationEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.applicationEmail && errors.applicationEmail && (
              <span className="error-message">{errors.applicationEmail}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_applicationPhone">
              Telefone *
            </label>
            <IMaskInput
              className={`form-control ${
                touched.applicationPhone && errors.applicationPhone
                  ? "-is-invalid"
                  : ""
              }`}
              id="id_applicationPhone"
              name="applicationPhone"
              placeholder="(88) 988888888"
              type="tel"
              value={values.applicationPhone}
              mask="(00) 000000000"
              unmask={false}
              onAccept={value => {
                values.applicationPhone = value
              }}
              onBlur={handleBlur}
            />
            {touched.applicationPhone && errors.applicationPhone && (
              <span className="error-message">{errors.applicationPhone}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_applicationAddress">
              Endereço *
            </label>
            <input
              className={`form-control ${
                touched.applicationAddress && errors.applicationAddress
                  ? "-is-invalid"
                  : ""
              }`}
              id="id_applicationAddress"
              name="applicationAddress"
              placeholder="Rua Exemplo Walfredo Luiz, 239"
              type="text"
              value={values.applicationAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.applicationAddress && errors.applicationAddress && (
              <span className="error-message">{errors.applicationAddress}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_applicationExperience">
              Você já teve alguma experiencia relacionada ao engajamento social?
              (Ongs, trabalho voluntario, associações...)? *
            </label>
            <Select
              inputId="id_applicationExperience"
              name="applicationExperience"
              className={`react-select-container ${
                touched.applicationExperience && errors.applicationExperience
                  ? "-is-invalid"
                  : ""
              }`}
              classNamePrefix="react-select"
              isSearchable={false}
              value={values.applicationExperience.selectedOption}
              onChange={e => {
                values.applicationExperience.selectedOption.value = e.value
                values.applicationExperience.selectedOption.label = e.label
              }}
              onBlur={handleBlur}
              options={values.applicationExperience.options}
            />
            {touched.applicationExperience && errors.applicationExperience && (
              <span className="error-message">
                {errors.applicationExperience.selectedOption.value}
              </span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_applicationOccupation">
              Qual cargo deseja ocupar? *
            </label>
            <Select
              inputId="id_applicationOccupation"
              name="applicationOccupation"
              className={`react-select-container ${
                touched.applicationOccupation && errors.applicationOccupation
                  ? "-is-invalid"
                  : ""
              }`}
              classNamePrefix="react-select"
              isSearchable={false}
              value={values.applicationOccupation.selectedOption}
              onChange={e => {
                values.applicationOccupation.selectedOption.value = e.value
                values.applicationOccupation.selectedOption.label = e.label
              }}
              onBlur={handleBlur}
              options={values.applicationOccupation.options}
            />
            {touched.applicationOccupation && errors.applicationOccupation && (
              <span className="error-message">
                {errors.applicationOccupation.selectedOption.value}
              </span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_applicationDescription">
              Descreva um pouco sobre o seu trabalho e suas experiências *
            </label>
            <textarea
              className={`form-control -textarea ${
                touched.applicationDescription && errors.applicationDescription
                  ? "-is-invalid"
                  : ""
              }`}
              id="id_applicationDescription"
              name="applicationDescription"
              placeholder="Breve descrição..."
              rows="10"
              value={values.applicationDescription}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {touched.applicationDescription &&
              errors.applicationDescription && (
                <span className="error-message">
                  {errors.applicationDescription}
                </span>
              )}
          </div>

          <div className="form-group">
            <label
              className="label"
              htmlFor="id_applicationCurriculumUploadFile"
            >
              Currículo *
            </label>
            <div
              className={`file-input ${
                touched.applicationCurriculumUploadFile &&
                errors.applicationCurriculumUploadFile
                  ? "-is-invalid"
                  : ""
              }`}
            >
              <div className="content">
                <input
                  name="applicationCurriculumUploadFile"
                  id="id_applicationCurriculumUploadFile"
                  type="file"
                  style={{ display: "none" }}
                  onChange={event => {
                    setFieldValue(
                      "applicationCurriculumUploadFile",
                      event.currentTarget.files[0]
                    )
                  }}
                  onBlur={handleBlur}
                  ref={fileInputCurriculum}
                />

                <button
                  className="btn-target"
                  type="button"
                  onClick={() => fileInputCurriculum.current.click()}
                >
                  Selecione o arquivo
                </button>

                <small className="placeholder-label">
                  <FileIcon className="icon" />
                  {values.applicationCurriculumUploadFile
                    ? values.applicationCurriculumUploadFile.name || "Error"
                    : "Selecione um arquivo"}
                </small>
              </div>
            </div>

            {touched.applicationCurriculumUploadFile &&
              errors.applicationCurriculumUploadFile && (
                <span className="error-message">
                  {errors.applicationCurriculumUploadFile}
                </span>
              )}
          </div>

          <div className="form-group -right">
            <Button
              type="submit"
              theme="primary"
              size="lg"
              full={true}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Enviar candidatura
            </Button>
          </div>
        </S.Form>
      )}
    </Formik>
  )
}

export default FormWork
